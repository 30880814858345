import classNames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { HandThumbUpIcon, IdentificationIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import { SignalIcon } from '@heroicons/react/24/solid';
import { EmptyState, Loading, LoadingProps, RevisionStateBadge, RevisionStateInfos } from '@src/components';
import { useGetArticleVideos, useGetDesignDetails, useGetProjectDetails } from '@src/hooks';
import { AnyVideoRevision, ArticleVideo, RevisionState } from '@src/models';
import * as routes from '@src/routes';
import { isEmpty } from '@src/utils';

const LoadingArticleVideos = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const ArticleVideosGrid = ({ brandId, articleId }: { brandId?: string; articleId?: string }) => {
  const { t } = useTranslation();
  const { isLoading, data: videos } = useGetArticleVideos(brandId, articleId);

  return (
    <>
      {isLoading && <LoadingArticleVideos title={t('components.article.ArticleVideosGrid.loading')} />}
      {!isLoading && videos && (
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="article-information-title" className="text-lg font-medium leading-6 text-gray-900">
              {t('components.article.ArticleVideosGrid.title')}
            </h2>
          </div>
          {!videos.length && brandId && (
            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
              <EmptyState
                title={t('components.article.ArticleVideosGrid.empty')}
                subtitle={t('components.article.ArticleVideosGrid.emptyGetStarted')}
                route={generatePath(routes.BRAND_DETAILS, { id: brandId })}
                buttonText={t('components.article.ArticleVideosGrid.emptyGetStartedButton')}
              />
            </div>
          )}
          {!isEmpty(videos) && (
            <ul className="grid grid-cols-1 gap-x-4 gap-y-4 border-t border-gray-200 px-4 py-6 sm:grid-cols-2 sm:px-6 xl:grid-cols-3">
              {videos.map(video => (
                <li key={video.id} className="relative">
                  <ArticleVideosGridCell video={video} />
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

const ArticleVideosGridCell = ({ video }: { video: ArticleVideo }) => {
  const { t } = useTranslation();

  const revisions: AnyVideoRevision[] = video.data.revisions;
  const revisionsExist = !isEmpty(revisions);
  const latestRevision = revisionsExist ? revisions[revisions.length - 1] : undefined;
  const videoType = video.data.videoType;
  const isDesign = video.data.settings.design;

  return (
    <Link
      to={generatePath(routes.VIDEO_DETAILS, {
        brandId: video.brandId,
        articleId: video.articleId,
        videoId: video.id
      })}
      className={classNames(
        'flex flex-col justify-end overflow-hidden rounded-2xl border border-gray-300',
        !revisionsExist && 'pointer-events-none'
      )}
    >
      <div className="flex items-center justify-between bg-gray-100 px-6 py-3">
        <div className="flex items-center gap-4">
          <div
            className={classNames(
              'flex h-8 w-8 items-center justify-center rounded-lg',
              videoType === 'SUMMARY_SLIDESHOW' && 'bg-yellow-500',
              videoType === 'SOCIAL_MEDIA' && 'bg-indigo-500',
              videoType === 'PRESENTER' && 'bg-green-500'
            )}
          >
            {videoType === 'SUMMARY_SLIDESHOW' && <ViewColumnsIcon className="h-4 w-4 text-white" />}
            {videoType === 'SOCIAL_MEDIA' && <HandThumbUpIcon className="h-4 w-4 text-white" />}
            {videoType === 'PRESENTER' && <IdentificationIcon className="h-4 w-4 text-white" />}
          </div>
          <h3
            className={classNames(
              'md:text-md line-clamp-3 text-base font-medium leading-6 text-gray-500 sm:line-clamp-2'
            )}
          >
            {t('components.article.ArticleVideosGrid.articleType', {
              context: videoType
            })}
          </h3>
        </div>
        {(!revisionsExist || latestRevision?.state === RevisionState.PROCESSING) && (
          <SignalIcon className="h-6 w-6 animate-spin text-gray-400" />
        )}
      </div>
      <div className="divide-y divide-gray-200 px-6 text-xs leading-6 xl:text-sm">
        <div className="flex justify-between py-3">
          <p className="text-gray-500">{t('general.common.language')}</p>
          <p className="text-gray-700">{video.data.settings.targetLanguage}</p>
        </div>
        <div className="flex justify-between py-3">
          <p className="text-gray-500">{t('components.common.revisions')}</p>
          <p className="text-gray-700">{revisions.length}</p>
        </div>
        {isDesign && (
          <DesignRow projectId={video.data.settings.projectId} templateId={video.data.settings.templateId} t={t} />
        )}
        {!isDesign && (
          <ProjectRow projectId={video.data.settings.projectId} templateId={video.data.settings.templateId} t={t} />
        )}
        {latestRevision && (
          <div className="flex items-center justify-between py-3">
            <p className="text-gray-500">{t('components.article.ArticleVideosGrid.lastRevision')}</p>
            <RevisionStateBadge revision={latestRevision} />
          </div>
        )}
      </div>
      <RevisionStateInfos revision={latestRevision} className="rounded-none px-6 py-3" />
    </Link>
  );
};

const DesignRow = ({ projectId, templateId, t }: { projectId: string; templateId: string; t: TFunction }) => {
  const { data: design } = useGetDesignDetails(projectId, true, { silentNotifications: true });

  const template = design?.variants.find(t => t.id === templateId);

  return (
    <>
      {design && (
        <>
          <div className="flex justify-between py-3">
            <p className="text-gray-500">{t('general.common.design')}</p>
            <p className="text-gray-700">{design.name}</p>
          </div>
          <div className="flex justify-between py-3">
            <p className="text-gray-500">{t('components.common.variant')}</p>
            <p className="text-gray-700">{template?.name}</p>
          </div>
        </>
      )}
    </>
  );
};

const ProjectRow = ({ projectId, templateId, t }: { projectId: string; templateId: string; t: TFunction }) => {
  const { data: project } = useGetProjectDetails(projectId, true, { silentNotifications: true });

  const template = project?.templates.find(t => t.id === templateId);

  return (
    <>
      {project && (
        <>
          <div className="flex justify-between py-3">
            <p className="text-gray-500">{t('components.common.project')}</p>
            <p className="text-right text-gray-700">{project.name}</p>
          </div>
          <div className="flex justify-between py-3">
            <p className="text-gray-500">{t('components.common.template')}</p>
            <p className="text-right text-gray-700">{template?.name}</p>
          </div>
        </>
      )}
    </>
  );
};
